/* montserrat regular */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/montserrat/Montserrat-Regular.eot'); /* IE9 Compat Modes */
    src: local('Montserrat-Regular'), local('Montserrat-Regular'),
         url('../fonts/montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat/Montserrat-Regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat/Montserrat-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat/Montserrat-Regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* montserrat light */
@font-face {
    font-family: 'Montserrat Light';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/montserrat/Montserrat-Light.eot'); /* IE9 Compat Modes */
    src: local('Montserrat-Light'), local('Montserrat-Light'),
         url('../fonts/montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat/Montserrat-Light.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat/Montserrat-Light.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat/Montserrat-Light.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat/Montserrat-Light.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* montserrat Medium */
@font-face {
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/montserrat/Montserrat-Medium.eot'); /* IE9 Compat Modes */
    src: local('Montserrat-Medium'), local('Montserrat-Medium'),
         url('../fonts/montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat/Montserrat-Medium.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat/Montserrat-Medium.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat/Montserrat-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat/Montserrat-Medium.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* montserrat SemiBold */
@font-face {
    font-family: 'Montserrat SemiBold';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/montserrat/Montserrat-SemiBold.eot'); /* IE9 Compat Modes */
    src: local('Montserrat-SemiBold'), local('Montserrat-SemiBold'),
         url('../fonts/montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat/Montserrat-SemiBold.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat/Montserrat-SemiBold.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* montserrat Bold */
@font-face {
    font-family: 'Montserrat Bold';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/montserrat/Montserrat-Bold.eot'); /* IE9 Compat Modes */
    src: local('Montserrat-Bold'), local('Montserrat-Bold'),
         url('../fonts/montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat/Montserrat-Bold.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat/Montserrat-Bold.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat/Montserrat-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat/Montserrat-Bold.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* GothamNarrow-Medium */
@font-face {
    font-family: 'GothamNarrow-Medium';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/gotham-narrow/GothamNarrow-Medium.woff'); /* IE9 Compat Modes */
    src: local('GothamNarrow-Medium'), local('GothamNarrow-Medium'),
         url('../fonts/gotham-narrow/GothamNarrow-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/gotham-narrow/GothamNarrow-Medium.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/gotham-narrow/GothamNarrow-Medium.woff') format('woff'), /* Modern Browsers */
         //url('../fonts/gotham-narrow/GothamNarrow-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/gotham-narrow/GothamNarrow-Medium.svg#OpenSans') format('svg'); /* Legacy iOS */
}