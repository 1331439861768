$color-black: #000000;
$color-white: #FFFFFF;
$color-light-blue: #00FFFF;

body {
    background-image: url("/build/images/background-image.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top 100% center;
    min-height: 100vh;
    font-family: 'Montserrat';
    //padding-bottom: 92px;
    padding-bottom: 160px;
    position: relative;
}
input:-internal-autofill-selected {
    background-color: rgb(0, 0, 0) !important;
}
b, strong {
    font-family: 'Montserrat Bold';
}
.container {
    max-width: 1140px;
}
.logo-title {
    display: flex;
    justify-content: left;
    &.home{
        justify-content: center !important;
    }
    a {
        position: relative;
        z-index: 999;
    }
    img {
        width: 150px;
        height: 150px;
    }
    h1.title {
        display: flex;
        align-items: center;
        padding: 20px;
        color: $color-black;
        font-size: 30px;
        line-height: 36px;
        font-family: 'Montserrat Bold';
        margin-bottom: 0;
        background-color: rgba(244, 244, 243, 0.7);
        h1{
            font-size: 2.0rem;
        }
        h2{
            font-size: 1.6rem;
            line-height: 33px;

        }
    }
    p {
        font-size: 18px;
        font-family: "Montserrat Medium";
    }
    .header-text-normal {
        font-family: 'Montserrat Medium';
    }
    .header-line {
        border: 4px solid #006E96;
        width: 222px;
    }
}
.header-nav {
    text-align: right;
    height: 30px;
    position: relative;
    z-index: 999;
    .contact, .logout, .menu-item {
        font-size: 12px;
        line-height: 1;
    }
    .contact {
        &::before {
            content: url("/build/images/mail-icon.svg");
            display: inline-block;
            width: 24px;
            height: 18px;
            position: relative;
            top: 4px;
            margin-right: 4px;
        }
    }
    .header-container {
        a {
            color: $color-black;
            text-transform: uppercase;
            font-family: 'Montserrat Medium';
            display: inline-block;
            margin-right: 16px;
            height: 35px;
            &:hover, &:focus {
                text-decoration: none;
                color: $color-light-blue;
            }
            &:last-child {
                margin-right: 0;
            }
            .personal-info {
                border-radius: 100%;
                background-color: #FFFFFF;
                height: 40px;
                width: 40px;
                margin-right: 15px;

                img {
                    margin-top: 6px;
                    margin-right: 6px;
                }
            }
        }
    }
}
footer {
    background-color: $color-black;
    padding: 14px 0;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    .inner {
        color: $color-white;
        font-size: 11px;
        font-family: 'Montserrat';
        
    }
    .mention-legales, .mention-legales:hover{
    	color: $color-white;
    	text-decoration: underline;
    }
    .social-icon {
        font-size: 30px;
    }
}

    
.sucess-message {
    margin-top: 60px;
    margin-bottom: 40px;
    padding: 50px 28px;
    background: #fff;
    font-family: 'Montserrat Medium';
    font-size: 14px;
    line-height: 16px;
    div {
        margin-bottom: 15px;
    }
}

.menu {
    margin-top: 100px;
}

@media (max-width: 767px) {
    .container {
        max-width: 100%;
        padding: 0 15px;
    }
    .header-nav {
        margin-bottom: 0;
        text-align: center;
        height: auto;
    }
    .header {
        margin-top: 10px;
        .logo-title {
            display: block;
            text-align: center;
            h1.title {
                margin-top: 10px;
                justify-content: center;
            }
            h2 {
                text-align: left;
            }
        }
    }
    footer {
        .inner {
            padding: 0 15px;
        }
    }
}
@media (max-width: 475px) {
    .page-login {
        .form-login {
            padding: 25px;
        }
    }
}
